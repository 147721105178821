import React from 'react';
import ReactDOM from 'react-dom';
import {
  extendTheme,
  ChakraProvider,
} from "@chakra-ui/react"
import App from './App';
import { Fonts } from './fonts';
import reportWebVitals from './reportWebVitals';

const extendedTheme = extendTheme({
  colors: {
    cakeBrown: '#5d4037',
    cakeYellow: '#fff8e1',
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={extendedTheme}>
      <Fonts />
      <App
        timestamp={Date.now()}
      />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
