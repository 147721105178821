import ReactEcharts from 'echarts-for-react';
import { PacmanLoader } from 'react-spinners';

import {
  BTC_ORANGE,
  MINING_RED,
  RATIO_BLUE,
} from '../constants';

function EChartsGraph(props) {
  const {
    data,
  } = props;

  if (!data) {
    return (
      <PacmanLoader
        color={BTC_ORANGE}
        css={{
          'left': '50%',
          'top': '50%',
        }}
      />
    );
  }

  const {
    btc_price: last_btc_price,
    price_cost_ratio: last_price_cost_ratio,
    production_cost: last_production_cost,
    date: last_date,
  } = data[data.length - 1];

  let xAxis = [],
    btc_prices = [],
    production_costs = [],
    price_cost_ratios = [];

  for (let i = 0; i < data.length; i++) {
    const {
      btc_price,
      date,
      price_cost_ratio,
      production_cost,
    } = data[i];

    xAxis.push(date);
    btc_prices.push(btc_price);
    production_costs.push(production_cost);
    price_cost_ratios.push(price_cost_ratio);
  }

  const textStyle = {
    fontWeight: 'bold',
    fontSize: 16,
    fontFamily: 'ABeeZee',
  };

  const textGraphicFont = 'bold 16px ABeeZee';

  const option = {
    xAxis: {
      data: xAxis,
      axisLabel: textStyle,
    },
    yAxis: [{
      type: 'value',
      axisLabel: textStyle,
      name: 'USD',
      nameTextStyle: textStyle,
      position: 'left',
    }, {
      type: 'value',
      axisLabel: textStyle,
      name: 'Ratio',
      nameTextStyle: textStyle,
      position: 'right',
    }],
    tooltip: {
      trigger: 'axis',
      textStyle: textStyle,
    },
    legend: {
      textStyle: textStyle,
    },
    dataZoom: [{
      type: 'inside',
    }, {
      type: 'slider',
    }],
    graphic: {
      elements: [{
        type: 'text',
        position: [150, 100],
        style: {
          text: 'Based on $0.06 USD/kWh',
          font: textGraphicFont,
        },
      }, {
        type: 'text',
        position: [150, 150],
        style: {
          text: `${last_date}:\n\nBTC: $${last_btc_price} USD\nCost: $${last_production_cost} USD\nRatio: ${last_price_cost_ratio}`,
          font: textGraphicFont,
        },
      }]
    },
    series:[{
      data: btc_prices,
      type: 'line',
      name: 'BTC Price',
      smooth: true,
      lineStyle: {
        color: BTC_ORANGE,
      },
      itemStyle: {
        color: BTC_ORANGE,
      },
    }, {
      data: production_costs,
      type: 'line',
      name: 'Production Cost',
      smooth: true,
      lineStyle: {
        color: MINING_RED,
      },
      itemStyle: {
        color: MINING_RED,
      },
    }, {
      data: price_cost_ratios,
      type: 'line',
      name: 'Price To Cost Ratio',
      smooth: true,
      yAxisIndex: 1,
      lineStyle: {
        color: RATIO_BLUE,
      },
      itemStyle: {
        color: RATIO_BLUE,
      },
    }],
  };

  return (
    <ReactEcharts
      option={option}
      style={{
        height: '100%',
        width: '100%',
      }}
    />
  );
}

export default EChartsGraph;
