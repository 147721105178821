import React from 'react';

function BuyMeACoffeeButton() {
  // const widget = (
  //   <script data-name="BMC-Widget" src="https://cdnjs.buymeacoffee.com/1.0.0/widget.prod.min.js" data-id="thecakeisalie" data-description="Support me on Buy me a coffee!" data-message="Thank you for visiting. You can now buy me a coffee!" data-color="#5d4037" data-position="Right" data-x_margin="18" data-y_margin="18"></script>
  // );


  return (
    <a href="https://www.buymeacoffee.com/thecakeisalie">
      <img
        src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=thecakeisalie&button_colour=5d4037&font_colour=ffffff&font_family=Poppins&outline_colour=ffffff&coffee_colour=fff8e1"
        alt="Click to buy me a coffee"
      />
    </a>
  );
}

export default BuyMeACoffeeButton;
