import create from 'zustand';
import axios from 'axios';

async function getData(set) {
  const { data } = await axios.get('/.netlify/functions/getProductionCosts');
  set({ data });
};

export const useStore = create(set => ({
  data: null,
  fetchData: async () => await getData(set),
}));
