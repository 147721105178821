import {
  SimpleGrid,
  useMediaQuery,
} from '@chakra-ui/react';
import React, { useEffect } from 'react'
import { Textfit } from 'react-textfit';

import { useStore } from './App.store';

import BuyMeACoffeeButton from './components/BuyMeACoffeeButton';
import EChartsGraph from './components/EChartsGraph';

function App(props) {
  const {
    timestamp,
  } = props;

  const fetchData = useStore(state => state.fetchData);
  const data = useStore(state => state.data);

  useEffect(() => {
    fetchData();
  }, [timestamp, fetchData]);

  return (
    <DankGrid>
      <SimpleGrid
        area='Header'
        fontFamily='Exo'
        fontWeight='bold'
        color='cakeBrown'
        margin='20px 10px 10px 20px'
      >
        <Textfit mode='single' max={30} style={{width: '100%'}}>
          Bitcoin Production Cost
        </Textfit>
      </SimpleGrid>
      <SimpleGrid
        area='Support'
        justifySelf='right'
        marginTop='10px'
        marginRight='10px'
      >
        {BuyMeACoffeeButton()}
      </SimpleGrid>
      <SimpleGrid area='Main' >
        <EChartsGraph data={data} />
      </SimpleGrid>
      <SimpleGrid
        area='Footer'
        fontFamily='ABeeZee'
        fontWeight='bold'
        marginLeft='20px'
        color='cakeBrown'
      >
        <Textfit mode='multi' max={20} style={{width: '80%'}}>
          Donation<br />BTC: bc1qelu5djulg894w93cxzn9vfks7tt2jzxsg2sdfh
        </Textfit>
        <Textfit mode='multi' max={20} style={{width: '80%'}}>
          Send Feedback to btcproductioncost@protonmail.com
        </Textfit>
      </SimpleGrid>
    </DankGrid>
  );
}

export default App;

function DankGrid(props) {
  const [ isGreaterThan800, isLandscape ] = useMediaQuery(['(min-width: 800px)', '(orientation: landscape)']);

  if (isLandscape) {
    return (
      <SimpleGrid
        className='App'
        height='100vh'
        width='100vw'
        templateColumns='1fr 1fr 1fr 1fr'
        templateRows='70px auto 140px'
        templateAreas={`'. Header Support .' '. Main Main .' '. Footer Footer .'`}
        bg='cakeYellow'
      >
        {props.children}
      </SimpleGrid>
    );
  } else if (isGreaterThan800) {
    return (
      <SimpleGrid
        className='App'
        height='100vh'
        width='100vw'
        templateColumns='1fr 1fr'
        templateRows='70px auto 70px'
        templateAreas={`'Header Support' 'Main Main' 'Footer Footer'`}
        bg='cakeYellow'
      >
        {props.children}
      </SimpleGrid>
    );
  } else {
    return (
      <SimpleGrid
        className='App'
        height='100vh'
        width='100vw'
        templateColumns='auto'
        templateRows='70px auto 70px 50px'
        templateAreas={`'Header' 'Main' 'Footer' 'Support'`}
        bg='cakeYellow'
      >
        {props.children}
      </SimpleGrid>
    );
  }
}